const langItem = document.querySelectorAll('.langNav li a');
const tableElement = document.querySelector('table');
const header = document.querySelector('header');

// responsive menu
$('.hamburger').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('body').toggleClass('active');
    $('.mobileMenu').slideToggle();
});

// remove 'active' class from body when a menu link is clicked
$('.mobileMenu a').click(function (e) {
    $('.hamburger').removeClass('active');
    $('body').removeClass('active');
    $('.mobileMenu').slideUp();
});

// clear content from modal on close
$('#contactModal').on('hidden.bs.modal', function (e) {
    $(this)
        .find("input,textarea")
        .val('')
        .end()
})

// active language
langItem.forEach(lang => {
    lang.addEventListener('click', (e) => {
        e.preventDefault();
        langItem.forEach(item => item.classList.remove('active'));
        e.currentTarget.classList.add('active');
    })
})

/*$(function () {
    try {
        $(".swipebox").swipebox();
    } catch (e) {
        console.error("swipebox not loaded");
    }
})*/

// jssocial
$(function () {
    try {
        $("#shareSocial").jsSocials({
            showLabel: false,
            showCount: false,
            shares: [
                {
                    share: "facebook",
                    logo: "/images/design/facebook.svg"
                },
                {
                    share: "twitter",
                    logo: "/images/design/twitter-x.svg"
                },
                {
                    share: "linkedin",
                    logo: "/images/design/linkedin.svg"
                }
            ]
        });
    } catch (e){
        console.log(e.message)
    }
})

// add parent div to table, add table class
tableElement.classList.add('table');

const tableWrapper = document.createElement('div');
tableWrapper.classList.add('table-responsive');

let parentDiv = tableElement.parentNode;
parentDiv.insertBefore(tableWrapper, tableElement);
tableWrapper.append(tableElement);


/*$(function () {
    $("form[name='contactForm']").validate({
        rules: {
            recipientName: "required",
            recipientMail: {
                required: true,
                email: true
            },
            messageText: "required",
        },
        messages: {
            recipientName: "Please enter your name",
            recipientMail: "Please enter a valid email address",
            messageText: "Please enter your message",
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});*/

// change header background when scroll
function headerScroll() {
    if(window.scrollY > 50) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled')
    }
}

document.addEventListener('scroll', headerScroll);
document.addEventListener('DOMContentLoaded', headerScroll);


// form submit alert
const alertPlaceholder = document.querySelector('.alertWrapper')

const alert = (message, type) => {
    const icons = {
        success: 'bi-check-circle-fill',
        warning: 'bi-exclamation-triangle-fill',
        danger: 'bi-x-circle-fill',
        info: 'bi-info-circle-fill'
    };

    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible show fade newStyle" role="alert">
           <div><i class="bi ${icons[type]}"></i> ${message}</div>
           <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>`
    ].join('')

    alertPlaceholder.append(wrapper);

    setTimeout(() => {
        const alertElement = wrapper.querySelector('.alert');
        if (alertElement) {
            setTimeout(() => {
                alertElement.remove();
            }, 150);
        }
    }, 5000);
}

const sendFormBtn = document.querySelector('#sendFormBtn')
const modalForm = document.querySelector('#modalForm')
const contactModal = new bootstrap.Modal(document.querySelector('#contactModal'));

sendFormBtn.addEventListener('click', async (event) => {
    event.preventDefault();
    alert('Please allow a few moments for your form to be submitted.', 'info');

    try {
        const response = await submitForm();

        if(response.success) {
            alert('Your message has been sent successfully!', 'success')
            contactModal.hide();
            modalForm.reset();
        } else {
            alert('There was an error while submitting your form. Please try again.', 'danger')
        }
    } catch (error) {
        alert('There was an error while submitting your form. Please try again.', 'danger')
    }
})

// form send simulation
function submitForm() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const success = Math.random() > 0.5;
            if(success) {
                resolve({success: true});
            } else {
                resolve({success: false})
            }
        }, 2000);
    })
}

// alert('Your message has been sent successfully!', 'success')
// alert('There was an error while submitting your form. Please try again.', 'danger');
// alert('Some fields are missing or incorrect.  Please try again.', 'warning');
// alert('Please allow a few moments for your form to be submitted.', 'info');
